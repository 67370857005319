import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Travel.scss"; // Ensure this SCSS file matches the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function AddTravel() {
  const [country, setCountry] = useState("Nepal");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [image, setImage] = useState(null); // For handling the image
  const [routes, setRoutes] = useState("");
  const [map, setMap] = useState("");
  const [days, setDays] = useState("");
  const [maximumAltitude, setMaximumAltitude] = useState("");
  const [included, setIncluded] = useState("");
  const [notIncluded, setNotIncluded] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  const [groupTravel, setGroupTravel] = useState(false);
  const [hotelAccommodation, setHotelAccommodation] = useState(false);
  const [price, setPrice] = useState("");
  const [categoryId, setCategoryId] = useState(""); // State for selected category
  const [categories, setCategories] = useState([]); // State for fetching categories
  const [popularity, setPopularity] = useState(false); // New state for popularity
  const [travels, setTravels] = useState([]);
  const [editId, setEditId] = useState(null);
  const [currentImage, setCurrentImage] = useState(null); // Track current image during editing

  useEffect(() => {
    fetchTravels();
    fetchCategories(); // Fetch categories when the component mounts
  }, []);

  // Fetch all travels
  const fetchTravels = async () => {
    try {
      const response = await axios.get(`${API_URL}/travel`);
      setTravels(response.data);
    } catch (error) {
      console.error("Error fetching travels:", error.message);
      alert("Failed to fetch travels. Please try again.");
    }
  };

  // Fetch all categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
      alert("Failed to fetch categories. Please try again.");
    }
  };

  // Handle form submission for adding/updating travels
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("country", country);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("itinerary", itinerary);
    formData.append("routes", routes);
    formData.append("map", map);
    formData.append("days", days);
    formData.append("maximum_altitude", maximumAltitude);
    formData.append("included", included);
    formData.append("not_included", notIncluded);
    formData.append("difficulty_level", difficultyLevel);
    formData.append("group_travel", groupTravel ? 1 : 0);
    formData.append("hotel_accommodation", hotelAccommodation ? 1 : 0);
    formData.append("popularity", popularity ? 1 : 0); // Add popularity to form data
    formData.append("price", price);
    formData.append("category_id", categoryId); // Include the selected category ID

    // Append the image only if a new image is selected; otherwise keep the existing image
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/travel/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Travel updated successfully!");
      } else {
        await axios.post(`${API_URL}/travel`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Travel added successfully!");
      }
      fetchTravels();
      resetForm();
    } catch (error) {
      console.error("Error saving travel:", error.message);
      alert("Failed to save travel. Please try again.");
    }
  };

  // Handle delete travel
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/travel/${id}`);
      fetchTravels();
    } catch (error) {
      console.error("Error deleting travel:", error.message);
      alert("There was an error deleting the travel!");
    }
  };

  // Handle edit travel
  const handleEdit = (travel) => {
    setCountry(travel.country);
    setTitle(travel.title);
    setDescription(travel.description);
    setItinerary(travel.itinerary);
    setRoutes(travel.routes);
    setMap(travel.map);
    setDays(travel.days);
    setMaximumAltitude(travel.maximum_altitude);
    setIncluded(travel.included);
    setNotIncluded(travel.not_included);
    setDifficultyLevel(travel.difficulty_level);
    setGroupTravel(travel.group_travel);
    setHotelAccommodation(travel.hotel_accommodation);
    setPopularity(travel.popularity === 1); // Set popularity from the travel data
    setPrice(travel.price);
    setCategoryId(travel.category_id);
    setEditId(travel.id);
    setCurrentImage(`${API_URL}/travel/${travel.id}/image`); // Set the current image URL for display
  };

  // Reset form fields
  const resetForm = () => {
    setCountry("Nepal");
    setTitle("");
    setDescription("");
    setItinerary("");
    setImage(null); // Reset image to null
    setRoutes("");
    setMap("");
    setDays("");
    setMaximumAltitude("");
    setIncluded("");
    setNotIncluded("");
    setDifficultyLevel(1);
    setGroupTravel(false);
    setHotelAccommodation(false);
    setPopularity(false); // Reset popularity to false
    setPrice("");
    setCategoryId("");
    setEditId(null);
    setCurrentImage(null); // Clear the current image
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="travel-main-div">
      <div className="add-travel">
        <h1 className="title-travel">
          {editId ? "Update Travel" : "Add Travel"}
        </h1>
        <form onSubmit={handleSubmit}>
          {/* Other input fields here */}
          <div>
            <label>Country:</label>
            <select
              className="input-travel"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            >
              <option value="Nepal">Nepal</option>
              <option value="India">India</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Tibet">Tibet</option>
            </select>
          </div>
          <div>
            <label>Category:</label>
            <select
              className="input-travel"
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              required
            >
              <option value="">Select a Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Title:</label>
            <input
              className="input-travel"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Travel Title"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-travel"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Travel Description"
              required
            ></textarea>
          </div>
          <div>
            <label>Itinerary:</label>
            <textarea
              className="input-travel"
              value={itinerary}
              onChange={(e) => setItinerary(e.target.value)}
              placeholder="Travel Itinerary"
              required
            ></textarea>
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <div>
            <label>Routes:</label>
            <input
              className="input-travel"
              type="text"
              value={routes}
              onChange={(e) => setRoutes(e.target.value)}
              placeholder="Travel Routes"
            />
          </div>
          <div>
            <label>Map:</label>
            <input
              className="input-travel"
              type="text"
              value={map}
              onChange={(e) => setMap(e.target.value)}
              placeholder="Map URL"
            />
          </div>
          <div>
            <label>Days:</label>
            <input
              className="input-travel"
              type="number"
              value={days}
              onChange={(e) => setDays(e.target.value)}
              placeholder="Number of Days"
            />
          </div>
          <div>
            <label>Maximum Altitude:</label>
            <input
              className="input-travel"
              type="number"
              value={maximumAltitude}
              onChange={(e) => setMaximumAltitude(e.target.value)}
              placeholder="Maximum Altitude"
            />
          </div>
          <div>
            <label>Included:</label>
            <textarea
              className="input-travel"
              value={included}
              onChange={(e) => setIncluded(e.target.value)}
              placeholder="Included Items"
            ></textarea>
          </div>
          <div>
            <label>Not Included:</label>
            <textarea
              className="input-travel"
              value={notIncluded}
              onChange={(e) => setNotIncluded(e.target.value)}
              placeholder="Not Included Items"
            ></textarea>
          </div>
          <div>
            <label>Difficulty Level (1-5):</label>
            <input
              className="input-travel"
              type="number"
              min="1"
              max="5"
              value={difficultyLevel}
              onChange={(e) => setDifficultyLevel(e.target.value)}
              placeholder="Difficulty Level"
            />
          </div>
          <div>
            <label>Group Travel:</label>
            <input
              className="input-checkbox"
              type="checkbox"
              checked={groupTravel}
              onChange={(e) => setGroupTravel(e.target.checked)}
            />
          </div>
          <div>
            <label>Hotel Accommodation:</label>
            <input
              className="input-checkbox"
              type="checkbox"
              checked={hotelAccommodation}
              onChange={(e) => setHotelAccommodation(e.target.checked)}
            />
          </div>
          <div>
            <label>Popularity:</label>
            <input
              className="input-checkbox"
              type="checkbox"
              checked={popularity}
              onChange={(e) => setPopularity(e.target.checked)}
            />
          </div>
          <div>
            <label>Price:</label>
            <input
              className="input-travel"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Travel Price"
            />
          </div>
          <button className="button-add-travel" type="submit">
            {editId ? "Update Travel" : "Add Travel"}
          </button>
        </form>
      </div>

      <div className="travels-container">
        <h2 className="title-travel-result">Travels List</h2>
        {travels && travels.length > 0 ? (
          travels.map((travel) => (
            <div key={travel.id} className="travel-card">
              <div className="travel-info">
                <img
                  className="travel-image"
                  src={`${API_URL}/travel/${travel.id}/image`} // Ensure this matches the backend route
                  alt={travel.title}
                />
                <div>
                  <span className="travel-name">{travel.title}</span>
                </div>
              </div>
              <div className="travel-actions">
                <button onClick={() => handleEdit(travel)}>Edit</button>
                <button onClick={() => handleDelete(travel.id)}>Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No travels found.</p>
        )}
      </div>
    </div>
  );
}

export default AddTravel;
